<template>
  <register-container v-show="!singleEmployeeSelected">
    <template slot="header"> {{ pageTitle || $t('registration.registerVisit.title') }}</template>
    <template slot="subtitle">{{ pageSubtitle }} </template>
    <div class="d-flex flex-wrap my-container">
      <div v-if="!dense">
        <provider-profile
          v-for="employee in visibleEmployees"
          :key="employee.id"
          :picture="employee.profilePictureUrl"
          :name="`${employee.lastName}, ${employee.firstName}`"
          :showPicture="showPicture"
        >
          <template slot="content">
            <div class="employee-bio-container d-flex flex-column justify-space-between">
              <div
                class="employee-bio flex-grow-1 mb-6 pa-2 d-flex flex-column"
                v-if="showProviderBio"
              >
                <div
                  v-if="employee.location && employee.location.address && employee.location.name"
                  class="mb-3 flex-grow-1"
                >
                  {{ $t('registration.profile.location') }}:
                  <br />
                  <span v-if="employee.location.name">{{ employee.location.name }} <br /></span>
                  <span v-if="employee.location.suite">{{
                    `${employee.location.address.suite}-`
                  }}</span>

                  {{
                    `${employee.location.address.streetNumber} ${employee.location.address.streetName}`
                  }}<br />{{
                    `${employee.location.address.city} ${employee.location.address.province} ${employee.location.address.postalCode}`
                  }}
                </div>
                <div v-if="employee.specialties" class="my-3 flex-grow-1">
                  {{ $t('registration.profile.specialties') }}: {{ employee.specialties }}
                </div>
                <div v-if="employee.description" class="employee-description mt-3 flex-grow-0">
                  {{ employee.description }}
                </div>
              </div>
              <div>
                <v-btn
                  block
                  contained
                  :loading="selected === employee.id"
                  :disabled="selected !== null && selected !== employee.id"
                  color="primary"
                  class="schedule-visit font-weight-bold text-h6"
                  @click="onSelectEmployee(employee)"
                >
                  {{ $t('registration.registerVisit.scheduleVisit') }}</v-btn
                >
              </div>
            </div>
          </template>
        </provider-profile>
      </div>
      <div
        v-else
        class="d-flex provider-container__dense justify-center flex-wrap"
        style="width: 100%"
      >
        <div
          v-for="employee in visibleEmployees"
          :key="employee.id"
          class="d-flex flex-column align-center mb-8 px-4"
          :style="{ width: $vuetify.breakpoint.smAndDown ? '300px' : '200px' }"
        >
          <div class="mb-2">
            <v-sheet
              elevation="9"
              class="rounded-circle"
              :width="$vuetify.breakpoint.xsOnly ? '50px' : '50px'"
            >
              <v-img :src="employee.profilePictureUrl" :aspect-ratio="1 / 1" class="rounded-circle">
              </v-img>
            </v-sheet>
          </div>
          <div class="provider-name__dense text-center mb-2">
            {{ $t('format.fullName', employee) }}
          </div>
          <v-btn
            block
            contained
            :loading="selected === employee.id"
            :disabled="selected !== null && selected !== employee.id"
            class="select-provider--btn primary"
            @click="onSelectEmployee(employee)"
            >Select</v-btn
          >
        </div>
      </div>
    </div>
  </register-container>
</template>

<style scoped lang="scss">
.provider-name__dense {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.loading-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}
.my-container {
  max-width: 950px !important;
}
.employee-bio-container {
  height: 100%;
  width: 100%;
}
.schedule-visit {
  height: 70px !important;
  border-radius: 8px;
}

@media all and (min-width: 960px) {
  .my-container {
    width: 900px;
  }
  .employee-bio {
    max-height: 300px !important;
  }
  .employee-description {
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: visible;
  }
}
</style>
<script>
import to from 'await-to-js';
import RegisterContainer from '../components/RegisterContainer.vue';
import ProviderProfile from '../components/ProviderProfile.vue';

export default {
  name: 'RegisterVisit',
  components: { RegisterContainer, ProviderProfile },
  data() {
    return {
      // Dense = Show simplified provider profiles
      dense: false,
      scrollElement: null,
      showPicture: true,
      showProviderBio: true,
      selected: null,
      employees: [
        /* structure of provider objects:
        {
          firstName: String
          id: String
          lastName: String
          primaryRole: String
          profilePictureUrl: String
          location: {
            name: String
            address:  {
              streetNumber: String
              streetName: String
              suite: String
              city: String
              province: String
              postalCode: String
              country: String
            }
          }
          specialties: [
            {
              name: String
            },
          ],
          description: String
        }
        */
      ],
      visibleEmployees: [],
    };
  },
  computed: {
    availableEmployees() {
      return this.$store.state.registration.availableEmployees;
    },
    singleEmployeeSelected() {
      return this.availableEmployees.length === 1;
    },
    tenantUrl() {
      return this.$store.state.registration.tenantUrl;
    },
  },
  methods: {
    async getProviderTimeZone() {
      const [errors, query] = await to(
        this.$apollo.query({
          query: await this.$store.getters.getQuery('GetProviderTimeZone'),
          variables: {
            tenantUrl: this.tenantUrl || null,
          },
          fetchPolicy: 'no-cache',
        })
      );
      const { timeZone } = query.data.User[0].metaData;
      if (errors || !timeZone) {
        this.$store.commit('setNotification', {
          color: 'error',
          text: errors || this.$t('errors.noTimezone'),
          timeout: 3000,
        });
        return;
      }
      this.$store.commit('setRegistration', {
        providerTimeZone: timeZone,
      });
    },
    async onSelectEmployee(employee) {
      this.selected = employee.id;
      this.$store.commit('setRegistration', {
        employeeName: `Dr. ${employee.lastName}, ${employee.firstName} `,
        employeeId: employee.id,
        employeePicture: employee.profilePictureUrl,
        restrictShiftsByEmployee: true,
      });
      this.$store.commit('setLocation', employee?.location);
      await this.getProviderTimeZone();

      this.nextRoute();
    },
    nextRoute() {
      if (this.isPageflowV2) {
        this.$emit('next');
      } else {
        this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
      }
    },
    async getEmployees(userIds) {
      this.$store.commit('setLoading', true);
      const [errors, query] = await to(
        this.$apollo.query({
          query: await this.$store.getters.getQuery('GetUsersByProvider'),
          variables: {
            providerId: this.$store.state.registration.providerId,
            type: 'employee',
          },
          fetchPolicy: 'no-cache',
        })
      );
      if (errors) {
        this.$store.commit('setNotification', {
          color: 'error',
          text: 'No health care providers were found.',
          timeout: 3000,
        });
        this.$store.commit('setLoading', false);
      }
      this.assignEmployeeData(
        query.data.getUsersByProvider.filter((u) => !userIds?.length || userIds.includes(u.id))
      );
      if (this.singleEmployeeSelected) {
        this.onSelectEmployee(this.employees.find((e) => e.id === this.$route.query.e));
        this.$emit('next');
      }
      this.$store.commit('setLoading', false);
    },
    assignEmployeeData(employees) {
      this.employees = employees
        .reduce((acc, employee) => {
          const newEmployee = {
            firstName: null,
            id: null,
            lastName: null,
            primaryRole: null,
            profilePictureUrl: null,
            location: null,
            specialties: null,
            description: null,
          };
          Object.keys(employee).forEach((value) => {
            if (value !== 'specialties' && value !== null) {
              newEmployee[value] = employee[value];
            } else if (
              value === 'specialties' &&
              employee.specialties !== null &&
              employee.specialties.length > 0 &&
              employee.specialties[0] !== null
            ) {
              const specialties = employee.specialties.reduce((accum, specialty) => {
                return [...accum, specialty.name];
              }, []);
              newEmployee.specialties = specialties.join(', ');
            }
          });
          return [...acc, newEmployee];
        }, [])
        .sort((a, b) => this.$t('format.fullName', a).localeCompare(this.$t('format.fullName', b)));

      this.assignShownEmployees();
    },
    assignShownEmployees() {
      if (this.dense) {
        this.visibleEmployees = this.employees;
      } else {
        this.visibleEmployees = this.employees.slice(0, 3);
      }
    },
    onScroll() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        const { scrollTop, scrollHeight, offsetHeight } = this.scrollElement;
        if (scrollTop + offsetHeight + 10 < scrollHeight) return;
      } else {
        const { scrollY, innerHeight } = window;
        const { offsetHeight } = this.scrollElement;
        if (scrollY + innerHeight + 10 < offsetHeight) return;
      }

      const nextEmployee = this.visibleEmployees.length;
      if (this.employees.length === this.visibleEmployees.length) return;
      this.visibleEmployees.push(this.employees[nextEmployee]);
    },
  },
  mounted() {
    const { availableEmployees } = this.$store.state.registration;
    this.getEmployees(availableEmployees);
    // No need to lazy load employees when in dense mode
    if (this.dense) return;
    this.scrollElement = document.querySelector('.registration-content__wrapper');
    this.scrollElement.addEventListener('scroll', this.onScroll);
    this.scrollElement.addEventListener('touchmove', this.onScroll);
  },
  beforeDestroy() {
    if (this.dense) return;
    this.scrollElement.removeEventListener('scroll', this.onScroll);
    this.scrollElement.removeEventListener('touchmove', this.onScroll);
  },
};
</script>
