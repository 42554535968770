<template>
  <v-container
    fluid
    no-gutters
    class="px-2 py-4 pa-md-0 text-body-1 mt-0 mb-6 my-md-12 flex-grow-1"
  >
    <v-row no-gutters class="d-flex justify-center">
      <v-col cols="12" md="6">
        <div class="d-flex justify-center" v-if="showPicture">
          <v-sheet
            elevation="9"
            class="rounded-circle"
            :width="$vuetify.breakpoint.xsOnly ? '200px' : '300px'"
          >
            <v-img :src="imageSource" :aspect-ratio="1 / 1" class="rounded-circle"> </v-img>
          </v-sheet>
        </div>
        <div class="d-flex mt-12 mb-6 text-h6">
          <div class="provider-name__wrap">{{ name }}</div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <slot name="content"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped>
.provider-name__wrap {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
</style>
<script>
import image from '../images/register-appointment-request-placeholder.png';

export default {
  name: 'ProviderProfile',
  data() {
    return {
      image,
    };
  },
  computed: {
    imageSource() {
      if (this.picture) {
        return this.picture;
      }
      if (this.onDemand) {
        return image;
      }
      return '';
    },
  },
  props: {
    picture: {
      type: String,
      required: false,
    },
    name: {
      type: String,
    },
    onDemand: {
      type: Boolean,
    },
    showPicture: {
      type: Boolean,
      default: () => true,
    },
  },
};
</script>
